<template>
    <div class="app-content content" style="margin: 0; padding: 30px;">
        <div class="content-wrapper" >
            <div class="content-body">
                <div class="auth-wrapper auth-cover">
                    <div class="auth-inner row m-0">
                        <router-link class="brand-logo" to="/"></router-link>
                        <div class="d-none d-lg-flex col-md-6 align-items-center">
                            <div class="w-100 d-lg-flex align-items-center justify-content-center">
                                <img class="img-fluid"
                                    src="/app-assets/images/login-v2.svg"
                                    alt="Login V2"
                                />
                            </div>
                        </div>

                        <div class="d-flex col-md-6 align-items-center auth-bg px-2 p-lg-5">
                            <div class="col-md-12 mx-auto  pt-2 mobile-login">
                                <div style="margin-left: -15px;">
                                    <a class="nav-link" href="/"><img src="/app-assets/images/brand-logo.png" style="height: 45px;"></a>
                                </div>
                            <p class="card-text mb-2">User Acceptance</p>
                            <!-- <p style="color: #e35858" id="err-message"><b>{{emailVerificationMessage}}</b></p> -->
                            <!-- <h3 style="color: #e35858"><b>{{errMessage}}</b></h3> -->

                            <div class="">
                                <h2>{{ formData.email }}</h2>
                                <h3>{{ $route.query.company_name }}</h3>
                                <h3>{{ $route.query.user_role }}</h3>
                                <h3 v-if="formData.reset_password == 0">{{$route.query.company_name }}</h3>
                            </div>

                            <vForm :validation-schema="schema" @submit="accept">
                                <template v-if="formData.reset_password == 1">
                                <div class="mb-1">
                                    <label class="form-label" for="name">Full Name</label>
                                    <vField 
                                        name="name"
                                        v-model="formData.name"
                                        class="form-control" 
                                        id="name" 
                                        type="text" 
                                        placeholder="Full Name" 
                                    />
                                    <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                                </div>

                                    <div class="mb-1">
                                        <label class="form-label" for="login-password">Password</label>
                                        <div class="input-group input-group-merge form-password-toggle" v-if="!showPassword">
                                            <vField 
                                                class="form-control form-control-merge" 
                                                id="login-password" 
                                                type="password"
                                                v-model="formData.password" 
                                                name="password" 
                                                placeholder="***"
                                            />
                                            <span class="input-group-text cursor-pointer"  @click="togglePasswordShow"><i class="fas fa-eye"></i></span>
                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle" v-if="showPassword">
                                            <vField 
                                                class="form-control form-control-merge" id="login-password" type="text"
                                                v-model="formData.password" 
                                                name="password" 
                                                placeholder="············"
                                            /><span class="input-group-text cursor-pointer"  @click="togglePasswordShow"><i class="fas fa-eye-slash"></i></span>
                                        </div>
                                        <ErrorMessage name="password" class="invalid-feedback d-block qcont"/>
                                    </div>

                                    <div class="mb-1">
                                        <label class="form-label" for="password_confirmation">Confirm</label>
                                        <div class="input-group input-group-merge form-password-toggle" v-if="!showConfirmPassword">
                                            <vField 
                                                v-model="formData.password_confirmation"
                                                name="password_confirmation"
                                                class="form-control form-control-merge" 
                                                id="password_confirmation" 
                                                type="password"
                                                placeholder="***"
                                            />
                                            <span class="input-group-text cursor-pointer"  @click="toggleConfirmPasswordShow"><i class="fas fa-eye"></i></span>
                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle" v-if="showConfirmPassword">
                                            <vField 
                                                class="form-control form-control-merge" 
                                                id="password_confirmation" 
                                                type="text"
                                                v-model="formData.password_confirmation" 
                                                name="password_confirmation" 
                                                placeholder="***"
                                            /><span class="input-group-text cursor-pointer"  @click="toggleConfirmPasswordShow"><i class="fas fa-eye-slash"></i></span>
                                        </div>
                                        <ErrorMessage name="password_confirmation" class="invalid-feedback d-block qcont"/>
                                    </div>
                                </template>

                                <button v-if="!loading" class="btn btn-primary w-100" tabindex="4">Accept</button>
                                <button v-else disabled class="btn btn-primary w-100" tabindex="4">
                                <span class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
                                Accept
                                </button>
                            </vForm>
                        </div>
                        </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import handleUser from '@/services/modules/user'
import { inject } from 'vue'

export default {
  name: 'Login',

  data: () => ({
    loading: false,
    errMessage:'',
    defaultButton:true,
    formData: {
        name: '',
        company_id:'',
        id: '',
        token: '',
        reset_password: '',
        email: '',
        password: '',
        password_confirmation: ''
    },
    showPassword: false,
    showConfirmPassword: false,
    schema: {
        //name:'required',
        //password_confirmation: 'confirmed:@password'
    }
  }),

  computed: {
    emailVerificationMessage() {
      return this.$route.query.verify
    }
  },

  methods: {
    resetForm() {
        Object.keys(this.formData).forEach(field => {
            this.formData[field]=''})
    },
    accept() {
        this.loading=true
        this.acceptCompanyRole(this.formData)
        .then(res => {
            this.loading=false
            if(res.status) {
                this.showSuccess(res.message)
                this.resetForm()
                this.$router.push('/login')
            }
            if(!res.status) {
                this.resetForm()
                this.showError(res.message)
            }
        })
        .catch(err => {
            this.loading=false
        })
    },
    togglePasswordShow(){
       this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordShow(){
       this.showConfirmPassword = !this.showConfirmPassword;
    },
  },

    setup() {
        const showError =  inject('showError')
        const showSuccess =  inject('showSuccess')
        const {allQuery, acceptCompanyRole} = handleUser()

        return {
            showError,
            showSuccess,
            allQuery,
            acceptCompanyRole
        }
    },
    mounted() {
        let formData = this.formData
        const {
            company_id,
            id,
            name,
            token,
            reset_password,
            email
        } = this.allQuery()

        formData.id= id
        formData.company_id= company_id
        formData.email = email
        formData.token = token
        if(reset_password != 0) {
            formData.name = name
        }
        formData.reset_password=reset_password
    }
}
</script>
